import router from '../router';

export const initSessionCheck = () => {
  const checkServerConnection = async () => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) {
      return;
    }

    try {
      const response = await fetch('https://api.dale365.com/api/v1/login/?check=1', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Token ${userToken}`
        }
      });

      const data = await response.json();

      // Se o token estiver expirado ou inválido (status 401)
      if (response.status === 401 || data.status === 'expired' || data.status === 'invalid') {
        forceLogout();
        return;
      }

      // Se o token for válido mas não estiver autenticado
      if (data.status === 'online' && !data.authenticated) {
        forceLogout();
        return;
      }

    } catch (error) {
      // Em caso de erro de conexão, mantém o usuário logado
      console.error('Erro ao verificar conexão:', error);
    }
  };

  const forceLogout = () => {
    localStorage.clear();
    router.push('/login');
  };

  // Verificação inicial
  checkServerConnection();

  // Verificação periódica a cada 30 segundos
  const interval = setInterval(checkServerConnection, 30000);

  return interval;
};