<template>
  <router-view />
</template>

<script>
import { onMounted, onUnmounted } from 'vue';
import { initSessionCheck } from './utils/sessionManager';

export default {
  name: 'App',
  setup() {
    let checkInterval;

    onMounted(() => {
      checkInterval = initSessionCheck();
    });

    onUnmounted(() => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    });
  }
};
</script>

<style>
/* Remove global overflow restrictions */
body, html {
  height: 100%;
}

#app {
  height: 100%;
}

/* Retain mobile-specific styles if needed */
@media (max-width: 768px) {
  body, html, #app {
    height: auto;
  }
}
</style>
